import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInventoryList() {
  // Use toast
  const toast = useToast()

  const refInventoryItemListTable = ref(null)

  const perPage = ref(5)
  const totalInventoryItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const branchFilter = ref(null)
  const categoryFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInventoryItemListTable.value ? refInventoryItemListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInventoryItems.value,
    }
  })

  const refetchData = () => {
    refInventoryItemListTable.value.refresh()
  }

  watch([currentPage, perPage, branchFilter, categoryFilter], () => {
    refetchData()
  })

  const fetchInventories = (ctx, callback) => {
    store
      .dispatch('inventory/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        branch_id: branchFilter.value,
        category_id: categoryFilter.value,
      })
      .then(response => {
        const { inventories, total } = response.data.data

        callback(inventories)
        totalInventoryItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting inventory list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchInventories,
    perPage,
    currentPage,
    totalInventoryItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInventoryItemListTable,

    refetchData,

    // Filters
    branchFilter,
    categoryFilter,

  }
}
